<template>
  <b-container fluid>
    <b-card>
      <h3 class="mb-2">Generate Voucher</h3>
      <validation-observer ref="generateVoucher">
        <b-row>
          <b-col>
            <b-form-group label="Code Prefix">
              <validation-provider
                #default="{ errors }"
                name="Code Prefix"
                rules="required"
              >
                <input
                  v-model="form.code_prefix"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Enter Code Prefix"
                  aria-label="Enter Code Prefix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
          <b-col>
            <b-form-group label="Code Postfix">
              <validation-provider
                #default="{ errors }"
                name="Code Postfix"
                rules="required"
              >
                <input
                  v-model="form.code_postfix"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Enter Code Postfix"
                  aria-label="Enter Code Postfix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Valid From">
              <validation-provider
                #default="{ errors }"
                name="Valid From"
                rules="required"
              >
                <flat-pickr
                  v-model="form.valid_from"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: 'today',
                  }"
                  class="form-control"
                  placeholder="Choose Date"
                  readonly="readonly"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
          <b-col>
            <b-form-group label="Valid To">
              <validation-provider
                #default="{ errors }"
                name="Valid To"
                rules="required"
              >
                <flat-pickr
                  v-model="form.valid_until"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: form.valid_from,
                  }"
                  class="form-control"
                  placeholder="Choose Date"
                  readonly="readonly"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="9">
                <b-form-group label="Amount">
                  <validation-provider
                    #default="{ errors }"
                    name="Amount"
                    rules="required|positive"
                  >
                    <input
                      v-model="form.amount"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Amount"
                      aria-label="Amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group
                ></b-col
              >
              <b-col
                ><b-form-group
                  label="Amount Option"
                  v-slot="{ ariaDescribedby }"
                  rules="required"
                >
                  <b-form-radio-group
                    id="createvoucher"
                    v-model="form.usage"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="createvoucher"
                  ></b-form-radio-group> </b-form-group
              ></b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-group label="Maximum Usage Limit">
              <validation-provider
                #default="{ errors }"
                name="Maximum Usage Limit"
                rules="required|positive"
              >
                <input
                  v-model="form.usage_limit"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Maximum Usage Limit"
                  aria-label="Maximum Usage Limit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
      </validation-observer>

      <hr class="mb-2" />
      <div class="row align-items-center justify-content-center">
        <div class="mx-2">
          <div class="row my-1">
            <div class="col">
              <span class="d-block">Voucher Code Preview:</span>
              <span class="d-block d-flex justify-content-center">
                <h1>{{ this.voucherPreview }}</h1>
              </span>
            </div>
          </div>
        </div>
        <div>
          <b-button
            variant="gradient-primary"
            class="box-shadow-1"
            @click="onSubmit"
            >Generate Voucher Code</b-button
          >
        </div>
      </div>
    </b-card>
    <b-card>
      <h3>List of Generated Vouchers</h3>
      <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
        <template v-slot="{ props }">
          <div
            v-if="props.column.field === 'date'"
            class="d-flex justify-content-center align-items-center"
          >
            {{ props.row.created_at.split("T")[0] }}
          </div>
          <div
            v-if="props.column.field === 'valid_date'"
            class="d-flex justify-content-center align-items-center"
          >
            {{ props.row.valid_from }} to {{ props.row.valid_until }}
          </div>
          <div
            v-if="props.column.field === 'used'"
            class="d-flex justify-content-center align-items-center"
          >
            {{ props.row.used_voucher }} / {{ props.row.usage_limit }}
          </div>
          <div
            v-if="props.column.field === 'user'"
            class="d-flex justify-content-center align-items-center"
          >
            <p v-if="props.row.assigned_user">
              {{ props.row.assigned_user.name }}
            </p>
            <p v-else>N/A</p>
          </div>
          <span v-if="props.column.field == 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              v-if="props.row.status != 'Cancelled'"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="assignVoucher(props.row)">
                <feather-icon icon="UserPlusIcon" class="mr-50" />
                <span v-if="props.row.assigned_user">Re-Assign</span>
                <span v-else>Assign</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editVoucher(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteVoucher(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </MyTable>
    </b-card>

    <!-- EDIT MODAL -->
    <b-modal ref="my-modal" hide-footer title="Edit Voucher" noCloseOnBackdrop>
      <validation-observer ref="generateEditVoucher">
        <b-row>
          <b-col>
            <b-form-group label="Code Prefix">
              <validation-provider
                #default="{ errors }"
                name="Code Prefix"
                rules="required"
              >
                <input
                  v-model="editForm.code_prefix"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Enter Code Prefix"
                  aria-label="Enter Code Prefix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
          <b-col>
            <b-form-group label="Code Postfix">
              <validation-provider
                #default="{ errors }"
                name="Code Postfix"
                rules="required"
              >
                <input
                  v-model="editForm.code_postfix"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Enter Code Postfix"
                  aria-label="Enter Code Postfix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Valid From">
              <validation-provider
                #default="{ errors }"
                name="Valid From"
                rules="required"
              >
                <flat-pickr
                  v-model="editForm.valid_from"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: editForm.valid_from,
                  }"
                  class="form-control"
                  placeholder="Choose Date"
                  readonly="readonly"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
          <b-col>
            <b-form-group label="Valid To">
              <validation-provider
                #default="{ errors }"
                name="Valid To"
                rules="required"
              >
                <flat-pickr
                  v-model="editForm.valid_until"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: editForm.valid_from,
                  }"
                  class="form-control"
                  placeholder="Choose Date"
                  readonly="readonly"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Amount">
                  <validation-provider
                    #default="{ errors }"
                    name="Amount"
                    rules="required|positive"
                  >
                    <input
                      v-model="editForm.amount"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Amount"
                      aria-label="Amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group
                ></b-col
              >
              <b-col class="d-flex justify-content-center align-items-center"
                ><b-form-group
                  label="Amount Option"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="editvoucher"
                    v-model="editForm.usage"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="editvoucher"
                  ></b-form-radio-group> </b-form-group
              ></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Maximum Usage Limit">
              <validation-provider
                #default="{ errors }"
                name="Maximum Usage Limit"
                rules="required|positive"
              >
                <input
                  v-model="editForm.usage_limit"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Maximum Usage Limit"
                  aria-label="Maximum Usage Limit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
          <b-col>
            <b-form-group label="Voucher Code">
              <validation-provider
                #default="{ errors }"
                name="Voucher Code"
                rules="required"
              >
                <input
                  v-model="editForm.voucher_code"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Enter Voucher Code"
                  aria-label="Enter Voucher Code"
                  readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          >
        </b-row>
      </validation-observer>
      <hr />
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="gradient-primary"
          class="box-shadow-1"
          @click="onSubmitEdit"
          >Save</b-button
        >
      </div>
    </b-modal>

    <!-- ASSIGN MODAL -->
    <b-modal
      ref="assign-voucher"
      hide-footer
      title="Assign Voucher"
      noCloseOnBackdrop
    >
      <validation-observer ref="generateEditVoucher">
        <b-row>
          <b-col>
            <b-form-group label="Voucher Code">
              <h2 class="d-flex justify-content-center align-items-center">
                {{ this.editForm.voucher_code }}
              </h2></b-form-group
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Assign User">
              <div
                class="form-group d-flex justify-content-center align-items-center"
              >
                <div
                  v-for="(option, index) in userFrom"
                  :key="index"
                  class="form-check form-check-inline"
                >
                  <b-form-radio
                    :name="option"
                    :value="option"
                    v-model="userType"
                    >{{ option }}</b-form-radio
                  >
                </div>
              </div>
              <div v-if="userType == 'Outlet'">
                <validation-provider
                  v-if="userType == 'Outlet'"
                  #default="{ errors }"
                  name="Outlet User"
                  rules="required"
                >
                  <v-select
                    v-model="userVouch.Outlet"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :clearable="false"
                    label="name"
                    required
                    placeholder="Assign Outlet User here"
                    class="font-small-3 w-100"
                  >
                    <template #option="option">
                      <span>{{ option.name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div v-else>
                <validation-provider
                  v-if="userType != 'Outlet'"
                  #default="{ errors }"
                  name="B2C User"
                  rules="required"
                >
                  <v-select
                    v-model="userVouch.b2c"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="b2cOptions"
                    :clearable="false"
                    label="name"
                    required
                    placeholder="Assign B2C User here"
                    class="font-small-3 w-100"
                  >
                    <template #option="option">
                      <span>{{ option.name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group></b-col
          >
        </b-row>
      </validation-observer>
      <hr />
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="gradient-primary"
          class="box-shadow-1"
          @click="onSubmitAssign"
          >Save</b-button
        >
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    extend,
    MyTable,
    vSelect,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      userFrom: ["Outlet", "B2C"],
      userType: "Outlet",
      userVouch: {},
      b2cOptions: [],
      userOptions: [],
      assignVouch: {},
      isLoading: false,
      options: [
        { text: "Per Box", value: "1" },
        { text: "Per Order", value: "2" },
      ],
      voucherPreview: "",
      form: {
        usage: "1",
        valid_from: new Date().toISOString().split("T")[0],
        valid_until: new Date(Date.now() + 172800000)
          .toISOString()
          .split("T")[0],
      },
      editForm: {},
      columns: [
        {
          label: "Date Created",
          field: "created_at",
          tdClass: "text-center align-middle",
        },
        {
          label: "Code",
          field: "voucher_code",
          tdClass: "text-center align-middle",
        },
        {
          label: "Validity",
          field: "valid_date",
          tdClass: "text-center align-middle",
        },
        {
          label: "Usage Limit",
          field: "used",
          tdClass: "text-center align-middle",
        },
        {
          label: "Assigned User",
          field: "user",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
    };
  },
  async created() {
    this.getVouchers();
    await this.$http.get(`getOutlet`).then((response) => {
      this.userOptions = response.data;
    });
    await this.$http.get(`users/b2c`).then((response) => {
      this.b2cOptions = response.data.data;
    });
  },
  methods: {
    getVouchers() {
      this.isLoading = true;
      this.$http.get(`voucher`).then((response) => {
        if (response.data.data?.length > 0) {
          this.rows = response.data.data.map((row) => {
            return {
              ...row,
              created_at: row.created_at.split("T")[0],
            };
          });
        } else {
          this.rows = [];
        }
        this.isLoading = false;
      });
    },
    onSubmit() {
      this.$refs.generateVoucher.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http.post("voucher ", this.form).then((response) => {
            if (response.data.status === false) {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.isLoading = false;
              return;
            }
            this.$swal({
              title: "Success !",
              icon: "success",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.voucherPreview = response.data.voucher_code;
            this.getVouchers();
          });
        }
      });
    },
    assignVoucher(data) {
      this.editForm = { ...data };
      this.userVouch = {};
      this.assignVouch = {};
      this.assignVouch.voucher_id = this.editForm.id;
      if (this.editForm.assigned_user) {
        if (this.editForm.assigned_user.branch_id == 0) {
          this.userType = "B2C";
          this.userVouch.b2c = this.editForm.assigned_user;
        } else {
          this.userType = "Outlet";
          this.userVouch.Outlet = this.editForm.assigned_user;
        }
      }
      this.$refs["assign-voucher"].show();
    },
    onSubmitAssign() {
      console.log(this.userType);
      this.$refs.generateEditVoucher.validate().then((success) => {
        if (success) {
          if (this.userType == "B2C") {
            this.assignVouch.user_id = this.userVouch.b2c.id;
          } else {
            this.assignVouch.user_id = this.userVouch.Outlet.id;
          }
          this.$http
            .post("assign_voucher ", this.assignVouch)
            .then((response) => {
              if (response.data.status === false) {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.isLoading = false;
                return;
              }
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getVouchers();
              this.$refs["assign-voucher"].hide();
            });
        }
      });
    },
    editVoucher(data) {
      this.editForm = { ...data };
      this.$refs["my-modal"].show();
    },
    onSubmitEdit() {
      this.$refs.generateEditVoucher.validate().then((success) => {
        if (success) {
          var editForm = {};
          editForm.voucher_code = this.editForm.voucher_code;
          editForm.voucher_id = this.editForm.id;
          this.$http.post("update_voucher ", editForm).then((response) => {
            if (response.data.status === false) {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.isLoading = false;
              return;
            }
            this.$swal({
              title: "Success !",
              icon: "success",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.voucherPreview = response.data.voucher_code;
            this.getVouchers();
            this.$refs["my-modal"].hide();
          });
          var editForm = {};
          editForm.code_postfix = this.editForm.code_postfix;
          editForm.code_prefix = this.editForm.code_prefix;
          editForm.valid_from = this.editForm.valid_from;
          editForm.valid_until = this.editForm.valid_until;
          editForm.amount = this.editForm.amount;
          editForm.usage_limit = this.editForm.usage_limit;
          editForm.usage = this.editForm.usage;
          this.$http
            .put(`voucher/${this.editForm.id} `, editForm)
            .then((response) => {
              if (response.data.status === false) {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.isLoading = false;
                return;
              }
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.voucherPreview = response.data.voucher_code;
              this.getVouchers();
            });
        }
      });
    },
    deleteVoucher(id) {
      this.$swal({
        title: "Delete this voucher?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          self.sending = true;
          const response = await this.$http.delete(`voucher/${id}`);
          if (response.data.status) {
            this.getVouchers();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
